import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Modal } from '@redq/reuse-modal';
import { theme } from '../theme';
import { ResetCSS } from '../assets/css/style';
import Sticky from 'react-stickynode';
import '../utils/i18n';
import Navbar from '../containers/NavbarAlt';
import Datenschutzerklaerung from '../containers/Datenschutzerklaerung';
import Footer from '../containers/Footer';
import GlobalStyle, { AppWrapper, ContentWrapper } from '../containers/global.style';
import '@redq/reuse-modal/es/index.css';

import SEO from '../components/seo';
export default function() {
  return (
    <ThemeProvider theme={theme}>
      <SEO title='Yush! Datenschutzerklärung' />
      <Modal />
      <ResetCSS />
      <GlobalStyle />
      <AppWrapper>
        <Sticky top={0} innerZ={9999} activeClass='sticky-active'>
          <Navbar />
        </Sticky>
        <ContentWrapper>
          <Datenschutzerklaerung />
        </ContentWrapper>
        <Footer />
      </AppWrapper>
    </ThemeProvider>
  );
}
