import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
// import Box from '../../components/Box';
import Text from '../../components/Text';
// import Image from '../../components/Image';
import Button from '../../components/Button';
import Logo from '../../components/Logo';
// import Heading from '../../components/Heading';
import { Icon } from 'react-icons-kit';
import Container from '../../components/Container';
import FooterArea, { MenuArea, CopyrightText } from './footer.style'; //WidgetArea, Menu, MenuItem,
import { instagram } from 'react-icons-kit/fa/instagram';
import { useTranslation } from 'react-i18next';
import { navigate } from 'gatsby';
import downloadApple from '../../assets/images/downloadAppleStore.png';
import downloadGoogle from '../../assets/images/downloadGooglePlay.png';

const Footer = () => {
  const { t } = useTranslation('home');

  const data = useStaticQuery(graphql`
    query {
      appModernJson {
        footer {
          logo {
            publicURL
          }
          menu {
            id
            link
            text
          }
          widgets {
            id
            icon {
              publicURL
            }
            title
            description
          }
        }
      }
    }
  `);
  const { logo } = data.appModernJson.footer;
  const date = new Date();
  const year = date.getFullYear();

  const onButtonPress = () => {
    window.open('https://www.instagram.com/yush.app/');
  };
  const onMailPress = () => {
    window.open('mailto:info@yush.app');
  };

  const onImpressumPress = () => {
    navigate('/impressum/');
  };

  const onDataPress = () => {
    navigate('/datenschutzerklaerung/');
  };

  const onRecipePress = () => {
    navigate('/recipes/');
  };

  const onTermsPress = () => {
    navigate('/termsofuse/');
  };

  const onApplePress = () => {
    window.open('https://apps.apple.com/de/app/yush/id1547039929');
  };

  const onGooglePress = () => {
    window.open('https://play.google.com/store/apps/details?id=app.yush');
  };

  return (
    <FooterArea>
      <Container>
        {/* <WidgetArea>
          {widgets.map(item => (
            <Box className='col' key={`footer-widget--key${item.id}`}>
              <Image src={item.icon.publicURL} alt={item.title} />
              <Heading as='h3' content={item.title} />
              <Text content={item.description} />
            </Box>
          ))}
        </WidgetArea> */}
        {/* End of footer widgets area */}
        <MenuArea>
          <div id='broadDiv'>
            <div id='leftDiv'>
              <Text content={t('footer.work')} />
              <div id='imageDiv'>
                <img src={downloadApple} alt='Apple' onClick={onApplePress} />
                <img src={downloadGoogle} alt='Google' onClick={onGooglePress} />
              </div>
            </div>
            <div id='middleDiv'>
              <Text as='h4' content={t('footer.corporation')} />
              <Text content={t('footer.contact')} onClick={onMailPress} />
              <Text content={t('footer.upload')} onClick={onRecipePress} />
              {/*               <Button variant='textButton' title={t('footer.contact')} onClick={onMailPress} />
              <Button variant='textButton' title={t('footer.upload')} onClick={onRecipePress} /> */}
            </div>
            <div id='rightDiv'>
              <Text content={t('footer.follow')} />
              <Icon size={28} icon={instagram} onClick={onButtonPress} className='insta' />
            </div>
            {/* <Button
              className='insta'
              // variant='extendedFab'
              icon={<Icon size={24} icon={instagram} style={{ padding: 0, margin: 0 }} />}
              iconPosition='left'
              //title={t('footer.follow')}
              onClick={onButtonPress}
            /> */}
            {/*  <div id='rightDiv'>
              <Text content='Äfdfddf' />
              <Text content='Äfdfddf' />
            </div> */}
          </div>
          {/* <Logo className='logo' href='/appclassic' logoSrc={logo.publicURL} title='Yush Application' />
          
          <Button
            className='mailButton'
            variant='textButton'
            iconPosition='right'
            title='info@yush.app'
            onClick={onMailPress}
            colors='warning'
          /> */}
          <div className='bottomLink'>
            <Button className='btnImpressum' variant='textButton' title='Impressum' onClick={onImpressumPress} />
            <Button className='btnTOC' variant='textButton' title={t('footer.privacy')} onClick={onDataPress} />
            <Button className='btnImpressum' variant='textButton' title={t('footer.terms')} onClick={onTermsPress} />
          </div>
          <CopyrightText>Copyright {year} By 3XCEED</CopyrightText>
        </MenuArea>
      </Container>
    </FooterArea>
  );
};

export default Footer;
