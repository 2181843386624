import React, { useState } from 'react';
import LogoImage from '../../assets/images/yushLogoWhite.png';
import LogoImageAlt from '../../assets/images/yushLogo.png';
import Logo from '../../components/Logo';
import Container from '../../components/Container';
import NavbarWrapper from './navbar.style';
import EnglishIcon from '../../assets/images/english-language.png';
import GermanIcon from '../../assets/images/german-language.png';
import i18next from 'i18next';
import Button from '../../components/Button';

const Navbar = () => {
  const [language, setLangauge] = useState(i18next.language);
  const [langText, setLangText] = useState(language.startsWith('de') ? 'English' : 'Deutsch');
  const [imgSrc, setImgSrc] = useState(language.startsWith('de') ? EnglishIcon : GermanIcon);

  const onLanguageChange = () => {
    if (langText === 'English') {
      i18next.changeLanguage('en');
      setLangauge('en');
      setLangText('Deutsch');
      setImgSrc(GermanIcon);
    } else {
      i18next.changeLanguage('de');
      setLangauge('de');
      setLangText('English');
      setImgSrc(EnglishIcon);
    }
  };

  return (
    <NavbarWrapper className='navbar'>
      <Container>
        <Logo href='/' logoSrc={LogoImage} title='Yush Application' className='main-logo' />
        <Logo href='/' logoSrc={LogoImageAlt} title='Yush Application' className='logo-alt' />
        <Button
          className='btnLanguage'
          title={langText}
          onClick={onLanguageChange}
          iconPosition='left'
          icon={<img src={imgSrc} variant='textButton' />}
        />
      </Container>
    </NavbarWrapper>
  );
};

export default Navbar;
